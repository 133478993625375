import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapMutations } from 'vuex';

import JobsApplications from '@/components/jobs-applications';
import { OPEN_MODAL } from '@/store/mutation-types';

import template from './applications.vue';

@Component({
  components: {
    JobsApplications
  },
  computed: {
    ...mapGetters({
      applications: 'job/applications'
    })
  },
  methods: {
    ...mapMutations({
      openModal: `modal/${OPEN_MODAL}`
    })
  },
  mixins: [template]
})
export default class Applications extends Vue {}
